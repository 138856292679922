import React from "react";
import Modal from "../Modal";
import XMark from "../../assets/images/x-mark.svg";
import "./PurchasePopup.scss";

const PurchasePopup = ({ isOpen, setIsOpen }) => {
  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className="purchasePopup">
        <img
          src={XMark}
          onClick={() => setIsOpen(false)}
          alt="x"
          className="purchasePopup_cross"
        />
        <div>
          Online registration coming soon. In the meantime, memberships can be
          purchased at the front desk or over the phone.
          <div style={{marginTop: "1rem"}}>
            <a href="tel:(425)470-8914">(425)470-8914</a>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PurchasePopup;
