import React, { useEffect, useRef, useState } from "react";
import "./BookTourPopup.scss";
import Modal from "../Modal";
import XMark from "../../assets/images/x-mark.svg";
import TextInput from "../TextInput";
import Textarea from "../Textarea";
import Button from "../Button";
import CongratsImage from "../../assets/images/congrats.svg";
import CalendarIcon from "../../assets/images/calendar-solid.svg";
import TimeIcon from "../../assets/images/clock-solid.svg";
import LocationIcon from "../../assets/images/location-dot-solid.svg";

const BookTourForm = ({ handleFormSubmit }) => {
  const messageRef = useRef();

  const [message, setMessage] = useState("");

  return (
    <>
      <h3> Join Us for Walk-In Wednesdays at Creator Zone ⚡ </h3>
      <p>
        For those who haven't yet stepped into the world of Creator Zone, it’s
        an open invite to feed your curiosity!
      </p>
      <div className="bookTourDetail">
        <img src={CalendarIcon} alt="calendar" />
        When: Every Wednesday
      </div>
      <div className="bookTourDetail">
        <img src={TimeIcon} alt="time" />
        Time: 4:00 PM - 7:00 PM
      </div>
      <div className="bookTourDetail">
        <img src={LocationIcon} alt="location" />
        Where: 9015 Vernon Rd Bldg G, Lake Stevens, WA 98258
      </div>
      {/* <form onSubmit={handleFormSubmit} className="bookTour_form">
        <div className="bookTour_formInputs">
          <TextInput
            label="First Name"
            placeholder="e.g. John"
            id="firstName"
            required
          />
          <TextInput
            label="Last Name"
            placeholder="e.g. Dowry"
            id="lastName"
            required
          />
        </div>
        <TextInput
          label="Email"
          placeholder="Add  your email address"
          id="email"
          type="email"
          required
        />
        <Textarea
          label="Additional Message"
          placeholder="Type a message here..."
          ref={messageRef}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
      </form> */}
      <a
        href="https://maps.app.goo.gl/FY7YyFJoTfUSC8z1A"
        rel="noreferrer"
        target="_blank"
      >
        <Button>Directions</Button>
      </a>
    </>
  );
};

const CongratsContent = ({ setIsOpen }) => {
  return (
    <div className="bookTour_submission">
      <img
        src={CongratsImage}
        alt="congrats"
        className="bookTour_congratsImage"
      />
      <h3>Thank you for booking a tour</h3>
      <p>
        Provide a brief explanation of the next steps in the process (e.g. you
        will receive an email or a representative will contact you etc)
      </p>
      <Button onClick={() => setIsOpen(false)}>Back to the website</Button>
      <div className="bookTour_subMsg">
        If you have any issues <span> contact us</span>
      </div>
    </div>
  );
};

const BookTourPopup = ({ isOpen, setIsOpen }) => {
  const [formSubmitted, setFormSubmitted] = useState(false);

  useEffect(() => {
    setTimeout(() => setFormSubmitted(false), 500);
  }, [isOpen]);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setFormSubmitted(true);
  };

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className="bookTour">
        <img
          src={XMark}
          onClick={() => setIsOpen(false)}
          alt="x"
          className="bookTour_cross"
        />
        {!formSubmitted ? (
          <BookTourForm handleFormSubmit={handleFormSubmit} />
        ) : (
          <CongratsContent setIsOpen={setIsOpen} />
        )}
      </div>
    </Modal>
  );
};

export default BookTourPopup;
