import React from "react";
import "./SummerCamp.scss";
import Logo from "../../assets/images/logo.svg";
import SummerCampQR from "../../assets/images/summerCampQR.png";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../components/Button";

const SummerCamp = () => {
  const navigate = useNavigate();

  return (
    <div className="summerCamp">
      <div className="container">
        <div className="summerCamp_logo">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-arrow-left"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="#000000"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
            onClick={() => navigate("/")}
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M5 12l14 0" />
            <path d="M5 12l6 6" />
            <path d="M5 12l6 -6" />
          </svg>
          <img onClick={() => navigate("/")} src={Logo} alt="creator-zone" />
        </div>
        <div className="summerCamp_heading">
          <h3>SUMMER CAMPS</h3>
        </div>
        <div className="summerCamp_topText">
          <p>
            Join us for 'Summer of STEAM' at Creator Zone. We'll be offering
            weekly interactive camps for students grades K - 8 (5-12 years old)
            centered around themes that converge art and technology!
          </p>
          <p>
            Camps will run weekly from the 24th of June through the 16th of
            August. Campers may sign up to as many weeks as they'd like.
          </p>
          <p>
            Drop Off Camps: Half Day Sessions 9am - 12noon / Full Day Sessions
            9am - 3pm
          </p>
        </div>

        <div className="summerCamp_paras">
          <div>
            <h4>Arts & Science</h4>
            <p>
              Explore the relationship between science and art to produce
              creative fusions! Campers will have the opportunity to experiment
              with the science behind popular activities including tie dye,
              paper making & marbling, DIY sidewalk chalk, and exploring the
              captivating world of luminescence with glow-in-the-dark painting.
            </p>
            <p>
              Session One: June 24 - 28 / Session Two: July 1-5 / Half Day: $250
              p/w / Full Day: $350 p/w
            </p>
          </div>
          <div>
            <h4>Wearable Art</h4>
            <p>
              Dip into a palette of electric hues and glow-in-the-dark materials
              to create a wearable work of art that is vibrant, bold, bright,
              and neon! Campers may apply electronics to celebrate neon energy
              or have the freedom to explore and create your own design. The
              only limit is your own imagination. Inspired by 'WOW' The World of
              Wearable Art!{" "}
            </p>
            <p>
              Session One: July 8-12 / Session Two: July 15-19 | Half Day: $250
              p/w / Full Day: $350 p/W
            </p>
          </div>
          <div>
            <h4>Craft Garden</h4>
            <p>
              Let's explore the natural world around us! Campers will have the
              chance to get their hands dirty outside dreaming up their own
              fairy gardens, building bird houses, sublimating custom wind
              spinners, making bubble wands and enjoying the process of mosaic
              art.
            </p>
            <p>
              Session One: July 22-26 / Session Two: July 29 - Aug 6 | Half Day:
              $250 p/w / Full Day: $350 p/w
            </p>
          </div>
          <div>
            <h4>Robotics</h4>
            <p>
              Race, soar, explore! Campers will explore daily exercises that
              teach early concepts of robotics and electronics. They'll then
              have the chance to put their new learnings to the test by creating
              something that moves - animating Legos, and building STEM kits!
              With mentors nearby, campers may have the opportunity to try the
              laser cutter, 3D printers, electronics, and crafts available at
              Creator Zone to construct a new invention!
            </p>
            <p>
              Session One: August 5-9 / Session Two: August 12-16 / Half Day:
              $275 p/w / Full Day: $375 p/w
            </p>
          </div>
          <Link
            target="_blank"
            to="https://docs.google.com/forms/d/e/1FAIpQLSfplpYUCsg9Wh_8_nWAJDz9wBikoQxpGB_f7XrKTHHd7E1ABg/viewform?pli=1"
            className="summerCamp_link "
          >
            <Button>Register Here</Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SummerCamp;
